import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { switchMap } from 'rxjs/operators';
import { ReportService } from 'src/app/services/report.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProcessType } from 'src/app/entities/processType';
import { ProcessTypeService } from 'src/app/services/proces-type.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
export interface DialogData {
  name: string;
}
@Component({
  selector: 'app-process-add-edit',
  templateUrl: './process-add-edit.component.html',
  styleUrls: ['./process-add-edit.component.css']
})
export class ProcessAddEditComponent implements OnInit {
  
  currentProcessId: number;
  // stages coming from the process structure
  stages = [];
  processType: ProcessType;
  missingStages = []
 
  fileName: string;
  fileNamePDF: string;
  excelType: any
  currentYear = new Date().getFullYear().toString()


  constructor(public processTypeService: ProcessTypeService, public reportService: ReportService, private router: Router, private route: ActivatedRoute,
    private spinner: NgxSpinnerService,  private toastr: ToastrService) {
      
  }

   
  ngOnInit() {
    this.processType = new ProcessType();
    this.processType['stages']=[]
    this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.
      if (params['id']){
        this.processTypeService.getProcessType(params['id']).subscribe(prType => {
          this.processType = prType
        })
      }
      
    });
    
  
  }

  populatePages(processType) {
    this.processType = processType;
    this.currentProcessId = this.processType.id;
    this.reportService.getPages(processType['key'])
      .subscribe(
        data => {
          this.stages = data.stages;
          var stgs = this.stages.filter(elem => elem.required === true).map(a => a.key);
          var x = this.processType['stages'].map(a => a.key);

        },
        err => {
          console.log(err);
        }
      );
  }
 

  list() {
    this.router.navigate(['formcreation_foradmin525']);
  }

  openInEditor(stage) {
    this.router.navigate(['/edit_form/'+this.processType.year+"/"+this.processType.key+"/"+ stage.key], {queryParams: {name:stage.name, type:stage.type}});
  }

  // moveStage(index,direction){
    

  //   if (direction===0){
  //     var obj2 = this.processType.stages[index]
  //     var obj1 = this.processType.stages[index+1]
  //     this.processType.stages[index+1] = obj2
  //     this.processType.stages[index] = obj1


  //     console.log("moving",index,"down");
  //   }else{
  //     console.log("moving",index,"up");
  //   }

  // }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.processType['stages'], event.previousIndex, event.currentIndex);
  }

  addNewStage(){
    var counter=1
  
    while (this.processType['stages'].find(elem=>elem.key==="stage_"+counter)){
      counter++;
    }
    var key = "stage_"+counter
 
   var x = {key:key, name: "new stage "+counter, type:"page", blocks:[]}
   this.processType['stages'].push(x);
  }

  saveProcess(){
    this.spinner.show()
    this.processTypeService.updateResource('process-types',this.processType).subscribe(result => {
      
      this.processType = result
      this.spinner.hide()
      this.toastr.success('Success!', `Process ${this.processType.name} successfully saved`, {
        toastClass: "ngx-toastr border-red",
        timeOut: 3000
      });
    })
    }

    deleteProcess(){
      this.spinner.show()
      this.processTypeService.deleteResource('process-types',this.processType).subscribe(result => {
        
        
        this.spinner.hide()
        this.toastr.success('Success!', `Process ${this.processType.name} successfully deleted`, {
          toastClass: "ngx-toastr border-red",
          timeOut: 3000
        });
        this.router.navigate(['/formcreation_foradmin525'])
      })
      }

  removeStage(key){
    this.processType['stages'].splice(key, 1);
  }
  

}

 