import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Column } from '../form-creator/column';


@Component({
  selector: 'rdf-formcreation-column-util',
  templateUrl: './column-util.component.html',
  styleUrls: ['./column-util.component.css']
})
export class ColumnUtilComponent implements OnInit {
  @Output() onColumnCreate = new EventEmitter<any>();
  columnBold = false;
  @Input() column: Column;
  currentColumnKey: any
  prevColumnKey: any
  columnTypes = ["string", "number", "date", "static text", "checkbox", "option"]
  constructor() { }

  ngOnInit() {
    if (this.column === undefined)
      this.column = new Column();
    this.currentColumnKey = this.column.key
    this.prevColumnKey = this.column.key
  }


  addColumn() {
    this.column.key = this.currentColumnKey;
    this.onColumnCreate.emit(this.column)
  }

}
