import { Component, OnInit, ViewChild, ElementRef, Output, Inject, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { MatPaginator, MatSort} from '@angular/material';
import { ProcessDataSource } from '../../services/process.datasource';
import { ProcessService } from '../../services/process.service';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'app-tx-declarations',
  templateUrl: './tx-declarations.component.html',
  styleUrls: ['./tx-declarations.component.css']
})
export class TxDeclarationsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['link', 'name', 'status', 'due_date', 'started_on', 'last_modified'];
  displayedColumnsSmall: string[] = ['link', 'name', 'status'];
  currentYear = new Date().getFullYear().toString()
  currentNIU='';
  currentYearInt = new Date().getFullYear()
  displayedYears: string[] = [this.currentYear, '' + (new Date().getFullYear() - 1)];
  dataSource: ProcessDataSource;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input', { static: false }) input: ElementRef;

  animal: string;
  name: string;
  constructor(private processService: ProcessService, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {

  }
 
  cellClicked(element) {
  }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('year')) {
      this.currentYear = this.route.snapshot.paramMap.get('year')
    }

    this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.
      if (params['niu']){
        this.currentNIU = params['niu']
      }
      
    });
    

    this.dataSource = new ProcessDataSource(this.processService)
  }


  handleSelection(event) {
    this.currentYear = event.value;
    this.dataSource.loadProcessess(this.currentYear, '{"username":"'+ this.currentNIU+'"}', 'asc', 'name', 0, this.paginator.pageSize);
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.dataSource.loadProcessess(this.currentYear, '{"username":"'+ this.currentNIU+'"}', 'asc', 'name', 0, this.paginator.pageSize);
    }, 0)

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;

          this.loadProcessPage();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadProcessPage())
      )
      .subscribe();

  }

  loadProcessPage() {
    this.dataSource.loadProcessess(
      this.currentYear,
      '{"query":"'+ this.input.nativeElement.value+'" , "username":"'+ this.currentNIU+'"}',
 
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }
  
}




