import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Row } from '../form-creator/row'


@Component({
  selector: 'rdf-formcreation-row-util',
  templateUrl: './row-util.component.html',
  styleUrls: ['./row-util.component.css']
})
export class RowUtilComponent implements OnInit {
  @Output() onRowCreate = new EventEmitter<any>();
  @Output() onRowUpdate = new EventEmitter<any>();
  @Output() onRowCancel = new EventEmitter<any>();
  @Input() row: Row;
  @Input() columns: [];
  @Input() stage: any;
  columnsInRows: boolean[]
  rowTypes = ["string", "number", "date", "checkbox"]
  styles = ["group", "text"]
  @Input() editingState = false;
  constructor() { }
  prevRowKey: any;
  currentRowKey: any
  ngOnInit() {
    if (this.row === undefined)
      this.row = new Row();
    this.currentRowKey = this.row.key
    this.prevRowKey = this.row.key
  }


  createRow() {
    this.row.key = this.currentRowKey;
    this.onRowCreate.emit(this.row)
  }

  updateRow(rowKey) {
    this.row.key = this.currentRowKey;
    this.onRowUpdate.emit({ row: this.row, prevRowKey: this.prevRowKey })
  }
  cancelEditing(){
    this.onRowCancel.emit()
  }


  calculateColumnInRowsClasses(index) {
    return {
      btn: true,
      'selected-component': this.columnsInRows[index]
    };
  }

}
