import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy, Input } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { TaxPayerStatusName } from 'src/app/entities/util';
import { TasDataSource } from 'src/app/services/tasGovTable.datasource';
import { UserService } from 'src/app/services/user.service';
import { UserDialogComponent } from '../dialogs/user-dialog/user-dialog.component';
import { SharedService } from 'src/app/services/sharedService';
import { TaxPayerService } from 'src/app/services/taxPayer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'tas-tax-payer',
  templateUrl: './tax-payers.component.html',
  styleUrls: ['./tax-payers.component.css']
})
export class TaxPayersComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() dec_key;
  @Input() dec_year;
  @Input() status;

  subscriptionUpdateUser: any;
  subscriptionDeactivateUser: any;
  subscriptionActivateUser: any;

  displayedColumns: string[] = ['tin', 'name', 'status'];
  dataSource: TasDataSource;
  statusNames = TaxPayerStatusName
  filterObject: {};
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;

  constructor(private taxPayerService: TaxPayerService, public dialog: MatDialog, public sharedService: SharedService, public router: Router) {
  }

  ngOnInit() {
    this.dataSource = new TasDataSource(this.taxPayerService)
    
    this.subscriptionDeactivateUser = this.sharedService.deactivateUserEmmitter.subscribe(val => {
      if (val) {
        this.taxPayerService.deactivateResource('tax-payers', val).subscribe(res => {
          this.loadTaxPayerData();
        })
      }
    })

    this.subscriptionActivateUser = this.sharedService.activateUserEmmitter.subscribe(val => {
      if (val) {
        this.taxPayerService.activateResource('tax-payers', val).subscribe(res => {
          this.loadTaxPayerData();
        })
      }
    })
  }

  ngAfterViewInit() {

    setTimeout(() => {
      
      if (this.dec_key && this.dec_year && this.status){
        this.filterObject['key'] = this.dec_key
        this.filterObject['year'] = this.dec_year
        this.filterObject['status'] = this.status
      }
    

      this.dataSource.loadData('tax-payers', '', 'asc', 'tin', 0, this.paginator.pageSize, this.filterObject);
    }, 0)

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadTaxPayerData();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadTaxPayerData())
      )
      .subscribe();

  }

  loadTaxPayerData() {
    this.dataSource.loadData('tax-payers',
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, this.filterObject);
  }

  openUserDialog(user): void {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: '600px', panelClass: 'custom-dialog-container',
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  goToTaxPayer(row){
    this.router.navigate(['tx_declarations'], { queryParams: { niu: row.username?row.username:row.tin }});
  }

  ngOnDestroy() {
    if (this.subscriptionUpdateUser) this.subscriptionUpdateUser.unsubscribe();
    if (this.subscriptionDeactivateUser) this.subscriptionDeactivateUser.unsubscribe();
    if (this.subscriptionActivateUser) this.subscriptionActivateUser.unsubscribe();
  }

}

