import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SharedService } from './sharedService';
import { BaseService } from './base.service';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
    providedIn: 'root'
})
export class ProcessTypeService extends BaseService{

    constructor(public http: HttpClient, public sharedService: SharedService) { 
        super(http, sharedService);
    }


    getProcessTypes(year) {
        let processTypes$ = this.http.get<any>(this.sharedService.apiUrl('process-types/'+year))
        return processTypes$;
    }

    getProcessType(id) {
        let processTypes$ = this.http.get<any>(this.sharedService.apiUrl('process-types/'+id))
        return processTypes$;
    }

    
}
