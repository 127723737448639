 import { UserStatus } from './states/userStatus';
import { Role } from './role';
import { TaxPayerStatus } from './states/taxPayerStatus';
 


 

const UserStatusName = new Map<number, string>([
  [UserStatus.ACTIVE, 'ACTIVE'],
  [UserStatus.SUSPENDED, 'SUSPENDED']
]);

const RoleName = new Map<number, string>([
  [Role.ADMIN, 'ADMIN'],
  [Role.USER, 'USER'],
  [Role.DIRECTOR, 'DIRECTOR'],
]);

const TaxPayerStatusName = new Map<number, string>([
  [TaxPayerStatus.ACTIVE, 'ACTIVE'],
  [TaxPayerStatus.SUSPENDED, 'SUSPENDED']
]);

 
 

var replaceValues = (obj1: any, obj2: any) => {
  for (var key in obj1) {
    delete obj1[key]
  }
  for (var key in obj2) {
    obj1[key] = obj2[key]
  }
}

export { UserStatusName, RoleName, replaceValues, TaxPayerStatusName }