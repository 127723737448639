import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { UserStatusName, replaceValues } from 'src/app/entities/util';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entities/user';
import { UserStatus } from 'src/app/entities/states/userStatus';
import { DialogCommunicator } from '../dialog-communicator';

@Component({
  selector: 'bog-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.css']
})
export class UserDialogComponent extends DialogCommunicator implements OnInit {

  text: string;
  emitter: EventEmitter;
  user: any;
  statusNames = UserStatusName
  userStatus = UserStatus
  branches = [];
  usernames = [];
  userroles = ['ADMIN','USER','DIRECTOR'];
  repeatPassword = "";
  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService, public userService: UserService, public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.setConfirmationDialog(this.confirmationDialog)
  }

  ngOnInit() {
    this.user = new User()
      // this.userService.getUserroles().subscribe(resUserRoles => {
      //   this.userroles = resUserRoles;
      // })
    
      if (this.data) {
        this.userService.getResource('user', this.data['id']).subscribe(val => {
          this.user = val
          this.user['class'] = 'USER'
          this.setEntity(this.user);
        }
        )
      } else {
        this.user.status = UserStatus.ACTIVE
        this.setEntity(this.user);
      }
  }

  public objectComparisonFunction = function (option, value): boolean {
    if (value)
      return option === value;
    return false;
  }

}
