import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ErrorpageComponent } from './components/errorpage/errorpage.component';
import { AuthService } from './services/auth.service';
import { SharedService } from './services/sharedService';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import localeFr from '@angular/common/locales/fr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatToolbarModule, MatIconModule, MatInputModule, MatFormFieldModule, MatSidenavModule, MatListModule, MatButtonModule,
  MatPaginatorModule,
  MatCardModule,
  MatTableModule,
  MatSortModule,
  MatDialogModule,
  MatMenuModule,
  MatPaginatorIntl,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTabsModule,
  MatSelectModule,
  MatOptionModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatRadioModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatButtonToggleModule
} from '@angular/material';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConfigService } from './services/config.service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { AuthGuard } from './guards/auth.guard';
import { AuthInterceptor } from './util/auth.interceptor';
import { UserComponent } from './components/user/user.component';
import { UserService } from './services/user.service';
import { UserDialogComponent } from './components/dialogs/user-dialog/user-dialog.component';
import { TaxPayersComponent } from './components/tax-payers/tax-payers.component';
import { TxDeclarationsComponent } from './components/tx-declarations/tx-declarations.component';
import { MatPaginatorIntlTas } from './components/tx-declarations/MatPaginatorIntlTas';
import { ProcessTypeService } from './services/proces-type.service';
import { ProcessViewComponent } from './components/process/process-view/process-view.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReportService } from './services/report.service';
import { ProcessStepComponent } from './components/process/process-step/process-step.component';
import { MultiStepFormComponent } from './components/multi-step-form/multi-step-form.component';
import { TasTableComponent } from './components/tas-table/tas-table.component';
import { ThousandSeparatorDirective } from './directives/thousandSeparator.directive';
import { ThousandSeparatorPipe } from './pipes/thousand-separator.pipe';
import { OnlyNumber } from './directives/onlyNumberDirective';
import { CdkTableModule } from '@angular/cdk/table';
import { ToastrModule } from 'ngx-toastr';
import { FormCreatorComponent } from './components/form-creation/form-creator/form-creator.component';
import { ProcessTypeComponent } from './components/form-creation/process-type/process-type.component';
import { ProcessAddEditComponent } from './components/form-creation/process-type/process-add-edit/process-add-edit.component';
import { ColumnUtilComponent } from './components/form-creation/column-util/column-util.component';
import { RowUtilComponent } from './components/form-creation/row-util/row-util.component';
import { DeclarationsComponent } from './components/declarations/declarations.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { ReportingComponent } from './components/declarations/reporting/reporting.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TaxDivisionComponent } from './components/tax-division/tax-division.component';
import { TaxDivisionService } from './services/taxDivision.service';
import { StatDetailsComponent } from './components/statistics/stat-details/stat-details.component';
import { StatDecCompaniesComponent } from './components/statistics/stat-dec-companies/stat-dec-companies.component';
registerLocaleData(localeFr, 'fr');
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function tokenGetter() {
  return localStorage.getItem('tas_gov_access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    ErrorpageComponent,
    TopMenuComponent,
    ConfirmationDialogComponent,
    UserComponent,
    UserDialogComponent,
    TaxPayersComponent,
    TxDeclarationsComponent,
    ProcessViewComponent,
    ProcessStepComponent,
    MultiStepFormComponent,
    TasTableComponent,
    ThousandSeparatorDirective,
    ThousandSeparatorPipe,
    OnlyNumber,
    FormCreatorComponent,
    ProcessTypeComponent,
    ProcessAddEditComponent,
    ColumnUtilComponent,
    RowUtilComponent,
    DeclarationsComponent,
    StatisticsComponent,
    ReportingComponent,
    SettingsComponent,
    TaxDivisionComponent,
    StatDetailsComponent,
    StatDecCompaniesComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MatToolbarModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatFormFieldModule,
    DragDropModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTabsModule,
    MatMenuModule,
    MatDialogModule,
    MatOptionModule,
    MatRadioModule,
    ToastrModule.forRoot(),
    MatDatepickerModule,
    MatSortModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    HttpClientModule,
    NgxSpinnerModule,
    CdkTableModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    })
  ],
  providers: [AuthService, SharedService, ConfigService, AuthGuard, UserService, ProcessTypeService, ProcessTypeService,
    ReportService, TaxDivisionService,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlTas },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, ThousandSeparatorPipe],
  bootstrap: [AppComponent],
  entryComponents: [  UserDialogComponent,  
    ConfirmationDialogComponent]
})
export class AppModule { }
